import { TABLE } from '/app.config';

const { view } = TABLE.icons;

const table = {
  data() {
    return {
      columns: [
        {
          width: 200,
          prop: 'nextUserName',
          label: '当前操作人',
          noUser: false,
          avatar: 'nextUserAvatar',
          other: 'department'
        },
        {
          width: 430,
          prop: 'orderInfo',
          label: '事项信息',
          noUser: true,
          hasInfo: true,
          other: ['orderId', 'reportTime', 'title']
        },
        {
          width: 150,
          prop: 'orderClassficationName',
          label: '事项类型',
          noUser: true
        },
        {
          width: 150,
          prop: 'matterClassficationName',
          label: '问题分类',
          noUser: true
        },
        {
          width: 150,
          prop: 'matterPropertyName',
          label: '问题性质',
          noUser: true
        },
        {
          width: 300,
          prop: 'statusName',
          label: '状态',
          dot: true,
          noUser: true
        }
      ],
      operations: {
        handles: [
          {
            icon: view,
            name: '查看',
            handle: this.view
          }
        ]
      }
    };
  }
};
export { table };
