<template>
  <div class="search-form">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="上报日期">
        <el-col :span="11">
          <el-date-picker type="date" placeholder="请选择开始日期" value-format="yyyy-MM-dd" v-model="form.reportTimeStart" style="width: 100%"></el-date-picker>
        </el-col>
        <el-col class="line" style="text-align: center" :span="2">-</el-col>
        <el-col :span="11">
          <el-date-picker type="date" placeholder="请选择结束时间" value-format="yyyy-MM-dd" v-model="form.reportTimeEnd" style="width: 100%"></el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item label="事项标题">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="事项状态">
        <el-checkbox-group v-model="form.statuss">
          <el-checkbox :label="item.value" v-show="item.value != ''" name="orderStatus" v-for="(item, index) in orderStatus" :key="index">{{ item.label }}</el-checkbox>
          <!-- <el-checkbox label="待处理" name="orderStatus"></el-checkbox>
          <el-checkbox label="已撤回" name="orderStatus"></el-checkbox>
          <el-checkbox label="待验收" name="orderStatus"></el-checkbox> -->
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="userTxt">
        <el-autocomplete v-model="realName" :fetch-suggestions="querySearchAsync" placeholder="请输入姓名" @select="handleSelect" @change="checkUser"></el-autocomplete>
      </el-form-item>
      <el-form-item label="完成时长" v-if="isComleted"> 超过<el-input v-model="form.time" style="width: 20%; margin: 0 10px"></el-input>小时 </el-form-item>
      <el-form-item label="发起人所在部门">
        <el-checkbox-group v-model="form.orderCreateDepts">
          <el-checkbox v-for="item in departmentList" :label="item.orderDeptName" :key="item.id" name="orderCreateDepts">{{ item.orderDeptName }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="经办责任部门">
        <el-checkbox-group v-model="form.orderBelongDepts">
          <el-checkbox v-for="item in departmentList" :label="item.orderDeptName" :key="item.id" name="orderBelongDepts">{{ item.orderDeptName }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="事项分类">
        <el-checkbox-group v-model="form.orderClassficationNames">
          <el-checkbox v-for="(item, index) in orderClassficationNamesList" :label="item.orderClassficationName" :key="index" name="orderClassficationNames">{{
            item.orderClassficationName
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="问题分类">
        <el-checkbox-group v-model="form.matterClassficationNames">
          <el-checkbox v-for="(item, index) in matterClassficationNamesList" :label="item.matterClassficationName" :key="index" name="matterClassficationNames">{{
            item.matterClassficationName
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="发生区域">
        <el-checkbox-group v-model="form.areaLocations">
          <el-checkbox v-for="(item, index) in areaLocationsList" :label="item.areaLocationName" :key="index" name="areaLocations">{{ item.areaLocationName }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button class="iconfont icon-dcsousuo" type="primary" @click="onSubmit">查询</el-button>
        <el-button class="iconfont icon-dcshuaxin1" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    userTxt: {
      type: String,
      default: '当前操作人'
    },
    tipTxt: {
      type: String,
      default: '操作人所在部门'
    },
    isComleted: {
      type: Boolean,
      default: false
    },
    orderStatus: Array
  },
  data() {
    return {
      userSource: [],
      realName: '',
      form: {
        title: '',
        orderClassficationNames: [],
        matterClassficationNames: [],
        statuss: [],
        nextUserId: '',
        orderCreateDepts: [],
        orderBelongDepts: [],
        areaLocations: [],
        reportTimeStart: '',
        reportTimeEnd: '',
        time: ''
      },
      departmentList: [],
      orderClassficationNamesList: [],
      matterClassficationNamesList: [],
      areaLocationsList: []
    };
  },
  mounted() {
    // this.restaurants = this.loadAll();
    this.getOrderList();
    this.getProblemList();
    this.getAreList();
    this.getDepartmentList();
  },
  methods: {
    //获取事项列表
    async getOrderList() {
      const { data } = await this.$apis.setOrder.list();
      this.orderClassficationNamesList = data;
    },
    //获取问题分类列表
    async getProblemList() {
      const { data } = await this.$apis.setProblem.list();
      this.matterClassficationNamesList = data;
    },
    //获取区域范围列表
    async getAreList() {
      const { data } = await this.$apis.setArea.list();
      this.areaLocationsList = data;
    },
    //获取部门列表
    async getDepartmentList() {
      const { data } = await this.$apis.setDepartment.list();
      this.departmentList = data;
    },
    onSubmit() {
      let params = {
        title: this.form.title,
        orderClassficationNames: this.form.orderClassficationNames.join(','),
        matterClassficationNames: this.form.matterClassficationNames.join(','),
        statuss: this.form.statuss.join(','),
        nextUserId: this.form.nextUserId,
        createUserId: this.form.createUserId,
        orderCreateDepts: this.form.orderCreateDepts.join(','),
        orderBelongDepts: this.form.orderBelongDepts.join(','),
        areaLocations: this.form.areaLocations.join(','),
        reportTimeStart: this.form.reportTimeStart,
        reportTimeEnd: this.form.reportTimeEnd,
        totalHours: this.form.time
      };
      this.$emit('search', params);
    },
    async querySearchAsync(queryString, cb) {
      const results = [];
      const { data } = await this.$apis.workOrder.getUserList({ realName: queryString });
      data.forEach(element => {
        results.push({
          value: element.realName,
          userId: element.userId
        });
      });
      cb(results);
      // cb(data);
      // let restaurants = this.restaurants;
      // let results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

      // clearTimeout(this.timeout);
      // this.timeout = setTimeout(() => {
      //   cb(results);
      // }, 3000 * Math.random());
    },
    createStateFilter(queryString) {
      return state => {
        return state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect(item) {
      if (this.isComleted) {
        this.form.createUserId = item.userId;
      } else {
        this.form.nextUserId = item.userId;
      }
    },
    checkUser(item) {
      if (!item) {
        if (this.isComleted) {
          this.form.createUserId = '';
        } else {
          this.form.nextUserId = '';
        }
      }
    },
    reset() {
      this.form = {
        title: '',
        orderClassficationNames: [],
        matterClassficationNames: [],
        statuss: [],
        nextUserId: '',
        createUserId: '',
        orderCreateDepts: [],
        orderBelongDepts: [],
        areaLocations: [],
        reportTimeStart: '',
        reportTimeEnd: ''
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.search-form {
  padding: 20px;
}
</style>
