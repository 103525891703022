<template>
  <div>
    <div class="main-box-white block-box">
      <section class="latest-status" :class="{ active: isSearch == '' }" @click="getData('')">
        <div>
          <span class="iconfont icon-quanbu status-wait"></span>
        </div>
        <div>
          <span class="status-num">{{ forHandleNumber + forAcceptanceNumber }}</span>
          <p>全部</p>
        </div>
      </section>
      <section class="latest-status" :class="{ active: isSearch == 'forHandle' }" @click="getData('forHandle')">
        <div>
          <span class="iconfont icon-daichuli status-wait"></span>
        </div>
        <div>
          <span class="status-num">{{ forHandleNumber }}</span>
          <p>待处理</p>
        </div>
      </section>
      <section class="latest-status" :class="{ active: isSearch == 'forAcceptance' }" @click="getData('forAcceptance')">
        <div>
          <span class="iconfont icon-daiyanshou status-sub"></span>
        </div>
        <div>
          <span class="status-num">{{ forAcceptanceNumber }}</span>
          <p>待验收</p>
        </div>
      </section>
    </div>
    <div class="main-box-white">
      <list-container
        :grid="false"
        ref="listContainerDom"
        placeholder="请输入关键字"
        :searchText.sync="searchText"
        :pagination="pagination"
        :uploadFile="uploadFile"
        :isAdd="false"
        @exportFile="exportFile"
        @fetch="loadData"
        @titleSearch="titleSearch($event)"
        :data="exportData"
        :fields="fields"
        excelName="正在进行的事项列表.xls"
      >
        <template v-slot:filter>
          <div class="select-box">
            <!-- <el-select v-model="orderClassficationNames" placeholder="请选择事项分类">
              <el-option value="" label="全部"></el-option>
              <el-option v-for="item in orderClassficationNamesList" :key="item.id" :label="item.orderClassficationName" :value="item.orderClassficationName"> </el-option>
            </el-select>
            <el-select style="margin-left: 20px" v-model="matterClassficationNames" placeholder="请选择问题分类">
              <el-option value="" label="全部"></el-option>
              <el-option v-for="item in matterClassficationNamesList" :key="item.id" :label="item.matterClassficationName" :value="item.matterClassficationName"> </el-option>
            </el-select>
            <el-select style="margin-left: 20px" v-model="status" placeholder="请选择问题状态">
              <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select> -->
            <el-button style="margin-left: 20px" class="iconfont icon-gengduoshaixuan" @click="drawer = true">高级搜索</el-button>
          </div>
        </template>
        <template v-slot:table>
          <base-table :data="data" :columns="columns" :operations="operations" :multi="false" />
        </template>
      </list-container>
      <el-drawer title="高级搜索" :visible.sync="drawer" direction="rtl" custom-class="search-drawer">
        <searchForm @search="search" :orderStatus="statusList" userTxt="当前操作人" />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import listContainer from '@/components/list-container/list-container';
import baseTable from '@/components/table/base-table/base-table';
import searchForm from '@/components/advanced-search/search-form';
import { formatDate } from '@/common/js/utils';
import { TABLE } from '/app.config';
import { table } from './mixin';
export default {
  name: 'working',
  mixins: [table],
  data() {
    return {
      forAcceptanceNumber: 0,
      forHandleNumber: 0,
      data: [],
      exportData: [],
      drawer: false,
      searchText: '',
      multipleSelection: [],
      pagination: {
        total: 0,
        pageSize: TABLE.page_sizes[0],
        currentPage: 1
      },
      orderClassficationNames: '',
      matterClassficationNames: '',
      status: '',
      orderClassficationNamesList: [],
      matterClassficationNamesList: [],
      searchParams: {},
      statusList: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'forHandle',
          label: '待处理'
        },
        {
          value: 'forAcceptance',
          label: '待验收'
        }
      ],
      isSearch: '',
      uploadFile: ['export'],
      fields: {
        创建日期: {
          field: 'createTime',
          callback: value => {
            return formatDate(value);
          }
        },
        当前操作人: 'nextUserName',
        事项状态: 'statusName',
        一级分类: 'orderClassficationName',
        二级分类: 'matterClassficationName',
        问题性质: 'matterPropertyName',
        标题: 'title',
        事项描述: {
          field: 'des',
          callback: value => {
            return `&nbsp;${value}`;
          }
        },
        建议及期望: {
          field: 'advice',
          callback: value => {
            return `&nbsp;${value}`;
          }
        }
      }
    };
  },
  created() {
    this.loadData();
    // this.getOrderList();
    // this.getProblemList();
    this.getNum();
  },
  methods: {
    //获取事项列表
    async getNum() {
      const { data } = await this.$apis.workOrder.getNum();
      this.forAcceptanceNumber = data.forAcceptanceNumber || 0;
      this.forHandleNumber = data.forHandleNumber || 0;
    },
    async getOrderList() {
      const { data } = await this.$apis.setOrder.list();
      this.orderClassficationNamesList = data;
    },
    //获取问题分类列表
    async getProblemList() {
      const { data } = await this.$apis.setProblem.list();
      this.matterClassficationNamesList = data;
    },
    //正在进行列表
    async loadData({ currentPage, currentPageSize } = {}) {
      this.pagination.currentPage = currentPage || this.pagination.currentPage;
      this.pagination.pageSize = currentPageSize || this.pagination.pageSize;

      const params = {
        statuss: this.isSearch,
        title: this.searchText,
        page: this.pagination.currentPage,
        limit: this.pagination.pageSize
      };
      const newParams = Object.assign({}, params, this.searchParams);
      this.loadDataAll(newParams);
    },
    //导出数据
    async getAll() {
      const params = {
        statuss: this.isSearch,
        title: this.searchText
      };
      const newParams = Object.assign({}, params, this.searchParams);
      const { data } = await this.$apis.workOrder.getAllList(newParams);
      this.exportData = data;
    },
    // 删除
    del({ id }) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { fail } = await this.$apis.sysUser.del({ ids: id });
        if (!fail) {
          this.loadData();
        }
      });
    },
    // 编辑
    edit({ id }) {
      this.$router.push({
        name: 'system_personal_edit',
        params: {
          id
        }
      });
    },
    // 详情
    view({ id }) {
      this.$router.push({ name: 'working_detail', params: { id } });
    },
    //导出
    exportFile() {},
    search(params) {
      this.drawer = false;
      this.$refs.listContainerDom.clear();
      this.searchParams = params;
      let newParams = Object.assign({}, params);
      newParams.page = 1;
      newParams.limit = this.pagination.pageSize;
      this.loadDataAll(newParams);
    },
    //状态筛选
    getData(status) {
      if (!status) {
        this.searchParams = {};
      }
      this.isSearch = status;
      let params = {
        page: 1,
        limit: this.pagination.pageSize,
        statuss: status
      };
      this.loadDataAll(params);
    },
    async loadDataAll(params) {
      const { data, count } = await this.$apis.workOrder.workingList(params);
      this.data = data;
      this.pagination.total = count;
      this.getAll();
    },
    titleSearch(searchText) {
      this.searchText = searchText;
      this.searchParams = {};
      this.loadData();
    }
  },
  watch: {
    // searchText() {
    //   this.loadData();
    // }
  },
  components: {
    listContainer,
    baseTable,
    searchForm
  }
};
</script>

<style lang="scss" scoped>
.personal {
  background: #fff;
  padding: 20px;
}
.block-box {
  display: flex;
  margin-bottom: 10px;
}
.latest-status {
  width: 184px;
  height: 64px;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-right: 16px;
  cursor: pointer;
  &:hover,
  &.active {
    box-shadow: 0px 2px 10px 0px rgba(18, 18, 18, 0.16);
  }
  .status-num {
    font-size: 20px;
    display: block;
    text-align: right;
    font-family: YouSheBiaoTiHei;
  }
  p {
    color: #5a5a5a;
    font-size: 14px;
    line-height: 1;
  }
  .iconfont {
    font-size: 30px;
  }
}
</style>
